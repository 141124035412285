<template>
    <step-settings-wrapper title="Filter &amp; Effects" v-on:close="onClose" v-on:save="onSave">
        <div class="mt-1 max-w-lg mx-auto">

            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-2xl sm:text-3xl uppercase font-bold tracking-wider">
                    Filters
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="filtersEnabled" :value="preset.effectSettings.filtersEnabled" v-model="preset.effectSettings.filtersEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-2 pt-5 clearfix" :class="{'border-b border-gray-custom-3': !preset.effectSettings.framesEnabled}">
                <div class="float-left text-2xl sm:text-3xl uppercase font-bold tracking-wider">
                    Frames
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="framesEnabled" :value="preset.effectSettings.framesEnabled" v-model="preset.effectSettings.framesEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-8 pt-3 border-b border-gray-custom-3 flex flex-wrap -mx-3" ref="framesWrapper" v-if="preset.effectSettings.framesEnabled">
                <file-select-upload allowedTypes="image/*" :multiple="true" ref="fileUpload" @input="previewFrames">
                    <button type="button" class="bg-white text-black font-dense uppercase tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-none px-3 w-images outline-none mb-3">
                        <font-awesome-icon icon="plus" class="text-3xl pb-2 font-black"></font-awesome-icon>
                        <br>
                        Upload<br>a frame
                    </button>
                </file-select-upload>
                <template v-if="preset.effectSettings.availableFrames">
                    <div class="w-images px-3 mb-3 relative" v-for="image in preset.effectSettings.availableFrames" :key="image">
                        <img :src="api_url+'/media/'+image" alt="" class="object-cover" />
                        <a href="#" class="absolute -mt-2 mr-1 right-0 top-0 z-10 text-red-600" @click.prevent="removeFrameOrGif($event, image, 'frame')"><font-awesome-icon icon="times-circle" class="bg-white border-radius-50"></font-awesome-icon></a>
                    </div>
                </template>
            </div>

            <div class="pb-2 pt-5 clearfix" :class="{'border-b border-gray-custom-3':!preset.effectSettings.animatedImagesEnabled}">
                <div class="float-left text-2xl sm:text-3xl uppercase font-bold tracking-wider">
                    Animated GIF
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="animatedImagesEnabled" :value="preset.effectSettings.animatedImagesEnabled" v-model="preset.effectSettings.animatedImagesEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-8 pt-3 border-b border-gray-custom-3 flex flex-wrap -mx-3" ref="gifsWrapper" v-if="preset.effectSettings.animatedImagesEnabled">
                <file-select-upload allowedTypes="image/*" :multiple="true" ref="fileUpload2" @input="previewGifs">
                    <button type="button" class="bg-white text-black font-dense uppercase tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-none px-3 w-images outline-none mb-3">
                        <font-awesome-icon icon="plus" class="text-3xl pb-2 font-black"></font-awesome-icon>
                        <br>
                        Upload<br>a frame
                    </button>
                </file-select-upload>
                <template v-if="preset.effectSettings.animatedImages">
                    <div class="w-images px-3 mb-3 relative" v-for="image in preset.effectSettings.animatedImages" :key="image">
                        <img :src="api_url+'/media/'+image" alt="" class="object-cover" />
                        <a href="#" class="absolute -mt-2 mr-1 right-0 top-0 z-10 text-red-600" @click.prevent="removeFrameOrGif($event, image, 'gif')"><font-awesome-icon icon="times-circle" class="bg-white border-radius-50"></font-awesome-icon></a>
                    </div>
                </template>
            </div>

            <div class="pb-2 pt-5 clearfix" :class="{'border-b border-gray-custom-3':!preset.effectSettings.backgroundImagesEnabled}">
                <div class="float-left text-2xl sm:text-3xl uppercase font-bold tracking-wider">
                    Background
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="backgroundImagesEnabled" :value="preset.effectSettings.backgroundImagesEnabled" v-model="preset.effectSettings.backgroundImagesEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-8 pt-3 border-b border-gray-custom-3 flex flex-wrap -mx-3" ref="backgroundsWrapper" v-if="preset.effectSettings.backgroundImagesEnabled">
                <file-select-upload allowedTypes="image/*" :multiple="true" ref="fileUpload3" @input="previewBackgrounds">
                    <button type="button" class="bg-white text-black font-dense uppercase tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-none px-3 w-images outline-none mb-3">
                        <font-awesome-icon icon="plus" class="text-3xl pb-2 font-black"></font-awesome-icon>
                        <br>
                        Upload<br>a frame
                    </button>
                </file-select-upload>
                <template v-if="preset.effectSettings.availableBackgroundImages">
                    <div class="w-images px-3 mb-3 relative" v-for="image in preset.effectSettings.availableBackgroundImages" :key="image">
                        <img :src="api_url+'/media/'+image" alt="" class="object-cover" />
                        <a href="#" class="absolute -mt-2 mr-1 right-0 top-0 z-10 text-red-600" @click.prevent="removeFrameOrGif($event, image, 'background')"><font-awesome-icon icon="times-circle" class="bg-white border-radius-50"></font-awesome-icon></a>
                    </div>
                </template>
            </div>

            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-2xl sm:text-3xl uppercase font-bold tracking-wider">
                    Captions
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="filtersEnabled" :value="preset.effectSettings.captionsEnabled" v-model="preset.effectSettings.captionsEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-2xl sm:text-3xl uppercase font-bold tracking-wider">
                    Portrait Mode
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="filtersEnabled" :value="preset.effectSettings.portraitModeEnabled" v-model="preset.effectSettings.portraitModeEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-8 border-b border-gray-custom-3 clearfix">
                <h3 class="text-3xl uppercase font-bold tracking-wider pt-6 pb-1">Image Settings</h3>

                <div class="flex flex-wrap md:flex-no-wrap">

                    <div class="sliders w-full md:w-1/2 pr-10">

                        <div class="mb-6">
                            <label class="block text-xl uppercase font-bold tracking-wider">Brightness</label>
                            <input type="range" min="-0.5" max="0.5" step="any" v-model="preset.effectSettings.brightness" @input="updateFilterImage" />
                        </div>

                        <div class="mb-6">
                            <label class="block text-xl uppercase font-bold tracking-wider">Contrast</label>
                            <input type="range" min="0" max="2" step="any" v-model="preset.effectSettings.contrast" @input="updateFilterImage" />
                        </div>

                        <div class="mb-5">
                            <label class="block text-xl uppercase font-bold tracking-wider">Saturation</label>
                            <input type="range" min="0" max="2" step="any" v-model="preset.effectSettings.saturation" @input="updateFilterImage" />
                        </div>

                        <button type="button" class="mb-6 md:mb-0 pt-2 pb-r px-10 bg-white text-black font-dense uppercase tracking-wider text-2xl font-bold border-b-3 border-turquoise leading-tight outline-none focus:outline-none" @click="resetFilterImage">
                            Reset
                        </button>

                    </div>

                    <div class="w-full md:w-1/2">
                        <img ref="filterImage" src="../assets/images/image_filter.jpg" />
                    </div>

                </div>

            </div>

            <div class="pb-20">
                <!-- <h3 class="text-3xl uppercase font-bold tracking-wider pt-6 pb-1">AI Settings</h3> -->

                <div class="pt-6 pb-1 clearfix">
                    <div class="float-left text-2xl sm:text-3xl uppercase font-bold tracking-wider">
                        AI Settings
                    </div>
                    <div class="float-right pt-1">
                        <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="aiImageGenerationEnabled" :value="preset.effectSettings.aiImageGenerationEnabled" v-model="preset.effectSettings.aiImageGenerationEnabled" @change="updateAiImageGenerationEnabled"></toggle-button>
                    </div>
                </div>

                <div :class="{'hidden': !aiImageGenerationEnabled && !preset.effectSettings.aiImageGenerationEnabled}">

                    <div class="relative font-work-sans mt-4">
                        <label for="ai_image_strength" class="text-xs sm:text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon :icon="editIcon" class="text-lg pr-1 align-text-top"></font-awesome-icon> AI Image Strength </label>
                        <input type="number" name="aiImageStrength" id="ai_image_strength" class="bg-transparent w-full pb-1 outline-none border-b border-gray-custom-2 font-work-sans-medium tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" v-model="preset.effectSettings.aiImageStrength" step="any" onkeydown="return event.keyCode !== 69" />
                    </div>

                    <div class="relative font-work-sans mt-10">
                        <label for="ai_prompt" class="text-xs sm:text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon :icon="editIcon" class="text-lg pr-1 align-text-top"></font-awesome-icon> AI Prompt </label>
                        <textarea type="text" name="aiPrompt" id="ai_prompt" class="bg-transparent w-full pb-1 outline-none border-b border-gray-custom-2 font-work-sans-medium tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" v-model="preset.effectSettings.aiPrompt" @input="resizeTextarea($event)" ref="aiPrompt"></textarea>
                    </div>

                    <div class="relative font-work-sans mt-10">
                        <label for="ai_seed" class="text-xs sm:text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon :icon="editIcon" class="text-lg pr-1 align-text-top"></font-awesome-icon> AI Seed </label>
                        <input type="text" name="aiSeed" id="ai_seed" class="bg-transparent w-full pb-1 outline-none border-b border-gray-custom-2 font-work-sans-medium tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" v-model="preset.effectSettings.aiSeed" />
                    </div>

                    <div class="relative font-work-sans mt-10">
                        <label for="ai_image_generation_steps" class="text-xs sm:text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon :icon="editIcon" class="text-lg pr-1 align-text-top"></font-awesome-icon> AI Image Generation Steps </label>
                        <input type="number" name="aiImageGenerationSteps" id="ai_image_generation_steps" class="bg-transparent w-full pb-1 outline-none border-b border-gray-custom-2 font-work-sans-medium tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" v-model="preset.effectSettings.aiImageGenerationSteps" step="any" onkeydown="return event.keyCode !== 69" />
                    </div>

                </div>

            </div>

            <modal :showing="showModal" transition="scale" @close="showModal = false" class="text-center">
                    <div class="text-white text-2xl sm:text-4xl uppercase leading-tight font-bold tracking-wide">Are you sure you want<br>to remove this frame?</div>
                    <br>
                    <div class="flex -mx-2">
                            <a href="#" class="bg-white text-black font-dense tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight pt-2 pb-1 outline-none focus:bg-turquoise w-1/2 mx-2" @click.prevent="confirmRemove">YES</a>
                            <a href="#" class="bg-white text-black font-dense tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight pt-2 pb-1 outline-none focus:bg-turquoise w-1/2 mx-2" @click.prevent="cancelRemove">NO</a>
                    </div>
            </modal>
        </div>
    </step-settings-wrapper>
</template>

<script>
    // @ is an alias to /src
    import StepSettingsWrapper from '@/components/StepSettingsWrapper.vue';
    import { ToggleButton } from 'vue-js-toggle-button';
    import FileSelectUpload from "@/components/FileSelectUpload.vue";
    import { dom } from '@fortawesome/fontawesome-svg-core';
    dom.watch();
    import Modal from '@/components/Modal.vue';
    import { faEdit } from '@fortawesome/free-solid-svg-icons'

    export default {
        name: 'EffectSettings',
        props: {
            preset: Object,
            toggleButtonSettings: Object
        },
        data: function() {
            return {
                beforeEditing: {},
                api_url: process.env.VUE_APP_BOOTHCLUBAPP_API_ENDPOINT,
                showModal: false,
                targetRemoveEle: null,
                removeKey: null,
                removeFrame: false,
                removeGif: false,
                removeBackground: false,
                removeFrameKeys: [],
                removeGifKeys: [],
                removeBackgroundKeys: [],
                aiImageGenerationEnabled: !this.preset.effectSettings.aiImageGenerationEnabled,
            }
        },
        components: {
            StepSettingsWrapper,
            ToggleButton,
            FileSelectUpload,
            Modal
        },
        mounted: function() {
            this.beforeEditing = Object.assign({}, this.preset.effectSettings);
            
            let aiPrompt = document.getElementById('ai_prompt');
            aiPrompt.dispatchEvent(new Event("input"));
            aiPrompt.dispatchEvent(new Event("blur"));
            document.getElementById('ai_image_strength').dispatchEvent(new Event("blur"));
            document.getElementById('ai_seed').dispatchEvent(new Event("blur"));
            document.getElementById('ai_image_generation_steps').dispatchEvent(new Event("blur"));

            this.aiImageGenerationEnabled = this.preset.effectSettings.aiImageGenerationEnabled;

            this.updateFilterImage();
        },
        methods: {
            inputFocus: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '-22px';
                }
            },
            inputBlur: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '0';
                } else {
                    event.target.previousElementSibling.style.top = '-22px';
                }
            },
            onClose: function(toRoute) {
                //this.preset.effectSettings = Object.assign({}, this.beforeEditing);
                let filesSelected = 0;
                if( typeof this.$refs.fileUpload !== 'undefined' )
                    filesSelected += this.$refs.fileUpload.getTotalFiles();
                if( typeof this.$refs.fileUpload2 !== 'undefined' )
                    filesSelected += this.$refs.fileUpload2.getTotalFiles()
                if( typeof this.$refs.fileUpload3 !== 'undefined' )
                    filesSelected += this.$refs.fileUpload3.getTotalFiles()
                this.$emit('close', 'effectSettings', this.beforeEditing, filesSelected, toRoute);
            },
            onSave: async function() {
                let result = true, result2 = true, result3 = true;
                if( this.preset.effectSettings.framesEnabled ) {
                    result = await this.$refs.fileUpload.saveFiles();
                    if( result !== false ) {
                        this.preset.effectSettings.framesS3Keys = result;
                    }
                }
                if( this.preset.effectSettings.animatedImagesEnabled ) {
                    result2 = await this.$refs.fileUpload2.saveFiles();
                    if( result2 !== false ) {
                        this.preset.effectSettings.gifS3Keys = result2;
                    }
                }
                if( this.preset.effectSettings.backgroundImagesEnabled ) {
                    result3 = await this.$refs.fileUpload3.saveFiles();
                    if( result3 !== false ) {
                        this.preset.effectSettings.backgroundS3Keys = result3;
                    }
                }
                if( result !== false && result2 !== false && result3 !== false ) {
                    this.preset.effectSettings.removeFrameKeys = this.removeFrameKeys;
                    this.preset.effectSettings.removeGifKeys = this.removeGifKeys;
                    this.preset.effectSettings.removeBackgroundKeys = this.removeBackgroundKeys ;
                    this.$emit('save', 'effectSettings');
                }
            },
            createPreviewEle: function(file, frameOrGif) {
                const div = document.createElement('div'),
                    a = document.createElement('a'),
                    closeIcon = document.createElement('i'),
                    self = this;
                div.classList = 'w-images px-3 mb-3 relative new-frame';
                a.classList = 'absolute -mt-2 mr-1 right-0 top-0 z-10 text-red-600';
                a.setAttribute('href', '#');
                closeIcon.classList = 'fas fa-times-circle bg-white border-radius-50';
                a.appendChild(closeIcon);
                a.addEventListener('click', function(event) {
                    self.removeFrameOrGif(event, 'newFrame', frameOrGif);
                    event.preventDefault();
                });
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.classList = 'object-cover';
                img.alt = '';
                div.appendChild(img);
                div.appendChild(a);
                return div;
            },
            previewFrames: function(files) {
                for (let i = 0; i < files.length; i++) {
                    const div = this.createPreviewEle(files[i], 'frame');
                    this.$refs.framesWrapper.appendChild(div);
                }
            },
            previewGifs: function(files) {
                for (let i = 0; i < files.length; i++) {
                    const div = this.createPreviewEle(files[i], 'gif');
                    this.$refs.gifsWrapper.appendChild(div);
                }
            },
            previewBackgrounds: function(files) {
                for (let i = 0; i < files.length; i++) {
                    const div = this.createPreviewEle(files[i], 'background');
                    this.$refs.backgroundsWrapper.appendChild(div);
                }
            },
            removeFrameOrGif: function(event, key, from) {
                let node = event.target.parentNode, nodeName = node.nodeName;
                while( nodeName.toLowerCase() !== 'div' ) {
                    node = node.parentNode;
                    nodeName = node.nodeName;
                }
                this.removeKey = key;
                this.targetRemoveEle = node;
                if( from === 'frame' ) {
                    this.removeFrame = true;
                } else if( from === 'gif' ) {
                    this.removeGif = true;
                } else if( from === 'background' ) {
                    this.removeBackground = true;
                }
                this.showModal = true;
            },
            confirmRemove: function() {
                if( this.targetRemoveEle !== null ) {
                    if( this.removeKey === 'newFrame' ) {
                        let fileIndex = Array.from(this.targetRemoveEle.parentNode.querySelectorAll('.new-frame')).indexOf(this.targetRemoveEle);
                        if(this.removeFrame === true) {
                            this.$refs.fileUpload.removeFiles(fileIndex);
                        } else if(this.removeGif === true) {
                            this.$refs.fileUpload2.removeFiles(fileIndex);
                        } else if(this.removeBackground === true) {
                            this.$refs.fileUpload3.removeFiles(fileIndex);
                        }
                    } else {
                        if(this.removeFrame === true) {
                            this.removeFrameKeys.push(this.removeKey);
                        } else if(this.removeGif === true) {
                            this.removeGifKeys.push(this.removeKey);
                        } else if(this.removeBackground === true) {
                            this.removeBackgroundKeys.push(this.removeKey);
                        }
                    }
                    this.showModal = false;
                    this.targetRemoveEle.parentNode.removeChild(this.targetRemoveEle);
                    this.targetRemoveEle = null;
                    this.removeKey = null;
                    this.removeFrame = false;
                    this.removeGif = false;
                    this.removeBackground = false;
                }
            },
            cancelRemove: function() {
                this.showModal = false;
                this.targetRemoveEle = null;
                this.removeKey = null;
                this.removeFrame = false;
                this.removeGif = false;
                this.removeBackground = false;
            },
            resizeTextarea: function(event) {
                event.target.style.height = '35px';
                if( event.target.scrollHeight > 0 ) {
                    event.target.style.height = event.target.scrollHeight + "px";
                }
            },
            updateAiImageGenerationEnabled: function() {
                this.aiImageGenerationEnabled = this.preset.effectSettings.aiImageGenerationEnabled;
            },
            updateFilterImage: function() {
                let brightness, contrast, saturation;

                if( this.preset.effectSettings.brightness == 0 ) {
                    brightness = 100;
                } else if(this.preset.effectSettings.brightness < 0) {
                    brightness = 100 - ( 100 * Math.abs(this.preset.effectSettings.brightness) );
                } else {
                    brightness = 100 + 100 * this.preset.effectSettings.brightness;
                }

                contrast = 100 * this.preset.effectSettings.contrast;

                saturation = 100 * this.preset.effectSettings.saturation;

                this.$refs.filterImage.style.filter = 'brightness('+brightness+'%) contrast('+contrast+'%) saturate('+saturation+'%)';
                
            },
            resetFilterImage: function() {
                this.preset.effectSettings.brightness = 0;
                this.preset.effectSettings.contrast = 1;
                this.preset.effectSettings.saturation = 1;
                this.updateFilterImage();
            }
        },
        computed: {
            editIcon: function() {
                return faEdit;
            }
        }
    }
</script>

<style scoped>
label {
    transition: top 0.2s ease-out;
}
textarea {
    resize: none;
    height: 35px;
    overflow: hidden;
}
/* range sliders */
.sliders input[type=range]{
    height: 4px;
    outline: none;
    width: 100%;
    position: relative;
    top: -2px;
    accent-color: white;
}
</style>

<style>
    /*.upload-button-padding {
        padding-left: 28px;
        padding-right: 28px; && result2 !== false
        padding-top: 20px;
        padding-bottom: 20px;
    }*/
    .w-images {
        width: 132px;
        /*height: 136px;*/
    }
    button.w-images {
        height: 136px;
    }
    .border-radius-50 {
        border-radius: 50%;
    }
</style>